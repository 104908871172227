import * as React from 'react';
import cn from 'classnames';

import { BaseModalProps } from 'shared/newEntities/components/BaseModal';
import { TranslationString } from 'shared/entities/localization';
import { ReferenceProps } from 'shared/newEntities/components/Reference';

import Modal from '../Modal';

import './ContentModal.modules.scss';

type Props = React.PropsWithChildren<
  BaseModalProps & {
    title: TranslationString | string;
    headerExtraComponent?: React.ReactNode;
    headerRightComponent?: React.ReactNode;
    fixHeight?: boolean;
    size?: 's' | 'm' | 'l';
    reference?: ReferenceProps;
  }
>;

const ContentModal: React.FC<Props> = ({
  children,
  fixHeight = true,
  size = 'l',
  ...rest
}) => {
  return (
    <Modal
      {...rest}
      styleName={cn(
        'content-modal',
        fixHeight && 'content-modal_fix-height',
        `content-modal_${size}`
      )}
    >
      {children}
    </Modal>
  );
};

export default ContentModal;
