import * as React from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';

import { useUiStore } from 'stores';
import { CommonDirection } from 'shared/entities/common/client';
import { useResizableWindow } from 'shared/entities/common/hooks';

import './ResizeableWindow.modules.scss';

const EXTRA_WIDTH = 472;
const MAX_WIDTH = 800;

type Props = {
  children: React.ReactNode;
  initialWidth?: number;
  onChangeWidth?: (value: number) => void;
};

const ResizeableWindow: React.FC<Props> = ({
  children,
  initialWidth = EXTRA_WIDTH,
  onChangeWidth
}: Props) => {
  const uiStore = useUiStore();
  const { onMouseDown, isDragging, width, ref } = useResizableWindow({
    initialWidth: initialWidth,
    directionOfExpansion: CommonDirection.left,
    minWidth: uiStore.isMobileSize ? undefined : EXTRA_WIDTH,
    maxWidth: MAX_WIDTH
  });

  React.useEffect(() => {
    if (typeof width !== 'number' || width === initialWidth) {
      return;
    }
    onChangeWidth?.(width);
  }, [width]);

  return (
    <>
      <div
        ref={ref}
        styleName={cn(
          'resizeable-window',
          isDragging && 'resizeable-window_unselect'
        )}
        style={{ width: width }}
      >
        <div styleName="resizeable-window__bar" onMouseDown={onMouseDown} />
        {children}
      </div>
    </>
  );
};

export default observer(ResizeableWindow);
