import { TranslationString } from '../localization';
import { WsEvent, WsEventBase } from '../ws/server';
import { MessageServer } from '../chatMessages';
import { BaseResponseCode } from '../network';
import { ScenarioDataServer } from '../scenario';
import { AIIntentKind, AITarget } from '../smartbotAIInfo';

export enum AIAggregatedDocType {
  pdf = 'pdf',
  site = 'site'
}

export type AIBotPayload = {
  name: string;
  target: AITarget;
  company_description: string | null;
  intent_kind: AIIntentKind;
  intent_text?: string;
  faq: string | null;
  limitations: string | null;
  model_type: AIModelKind;
  question_prompt: string;
  context_prompt: string;
};

export type AIUploadDocServerResponse = {
  agg_key: string;
};

export type AIUploadDocServerError =
  | {
      fields: Record<string, Array<string>>;
    }
  | AIDocError;

export type AIDocError = {
  code: AIDocErrorCode | number;
  text: string;
};

export type AIDocServer = {
  id: string;
  url: string;
  type: AIAggregatedDocType;
  cost: number;
  date_created: string;
  progress: number;
  errors?: AIDocError[];
};

export type AIDocsServer = {
  docs: AIDocServer[];
};

export type AIBotServer = {
  _id: string;
  test_messages_available: number;
  date_created: string;
  scenarios: Array<ScenarioDataServer>;
} & AIBotPayload;

export enum AIBotGetError {
  notFound = 'bot_not_found'
}

export const mapAIBotGetErrorToTitle =
  (code: AIBotGetError): TranslationString =>
  (t) =>
    t(`smartbotAI.errors.get.${code}`, {
      ns: 'entities'
    });

export type SessionDataServer = {
  session_id: string;
  subscription_token: string;
  subscription_channel: string;
  test_messages_available: number;
};

export type WsEventAIDemoMessage = WsEventBase<
  WsEvent.aiDemoMessage,
  MessageServer
>;

export enum SmartbotAIWidgetError {
  noTestMessagesAvailable = 'no_test_messages_available'
}

export const mapSmartbotAIWidgetErrorToMessage =
  (code: SmartbotAIWidgetError): TranslationString =>
  (t) =>
    t(`smartbotAI.errors.widget.${code}`, {
      ns: 'entities'
    });

export enum AIDocErrorResponseCode {
  internalError = 'internal_error'
}

export type AIDocErrorCode = BaseResponseCode | AIDocErrorResponseCode;

export enum AIModelKind {
  gpt35 = 'gpt_35',
  gpt4o = 'gpt_4o',
  yandexGpt = 'yandex_gpt',
  gpt4oMini = 'gpt_4o_mini'
}
