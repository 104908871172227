import * as React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import cn from 'classnames';

import Typography from 'shared/newComponents/Typography';
import {
  TypographyColor,
  TypographySize,
  TypographyType,
  TypographyWeight
} from 'shared/newEntities/components/Typography';
import { WithMetaProps } from 'shared/newEntities/components/WithMeta';
import CroppedContent from 'shared/newComponents/CroppedContent';
import Reference from 'shared/newComponents/Reference';

import './WithMeta.modules.scss';

type Props = WithMetaProps & {
  children: React.ReactNode;
  className?: string;
};

const WithMeta: React.FC<Props> = ({
  children,
  className,
  error,
  bottomLabel,
  label,
  errorLimited = true,
  labelSize = TypographySize.s,
  reference
}) => {
  const showError = !!error;

  return (
    <div className={className}>
      {label && (
        <div styleName={cn('meta__label', `meta__label_${labelSize}`)}>
          <Typography
            styleName={cn(`meta__label-title_${labelSize}`)}
            fontType={TypographyType.text}
            fontSize={TypographySize.s}
            fontWeight={TypographyWeight.heavy}
            fontColor={TypographyColor.secondary}
          >
            {label}
          </Typography>
          {reference && (
            <Reference styleName="meta__label-reference" {...reference} />
          )}
        </div>
      )}
      {children}
      {!showError && bottomLabel && (
        <Typography
          styleName="meta__bottom-label"
          fontType={TypographyType.text}
          fontSize={TypographySize.s}
          fontColor={TypographyColor.secondary}
        >
          {bottomLabel}
        </Typography>
      )}
      <AnimatePresence>
        {showError && (
          <motion.div
            style={{ overflowY: 'hidden' }}
            initial={{
              height: 0,
              opacity: 0,
              marginTop: 0
            }}
            animate={{
              height: 'auto',
              opacity: 1,
              marginTop: 6
            }}
            exit={{ height: 0, opacity: 0, marginTop: 0 }}
          >
            <CroppedContent popupContent={error}>
              <Typography
                fontType={TypographyType.text}
                fontSize={TypographySize.s}
                fontColor={TypographyColor.error}
                styleName={cn(errorLimited && 'meta__error')}
              >
                {error}
              </Typography>
            </CroppedContent>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default WithMeta;
