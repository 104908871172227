import { HexString } from 'shared/entities/color';
import { TranslationString } from 'shared/entities/localization';

export enum ProductOptionType {
  color = 'color',
  other = 'string'
}

/**
 * Сущность характеристики, приходящая с бэка
 */
export type ProductOptionServer = {
  // id значения характеристики
  id: number;
  // id характеристики
  option_id: number;
  type: ProductOptionType;
  name: string;
  value: string; // Значение характеристики, в случае цвета - название цвета
  extra_value?: HexString; // В случае цвета - HEX-цвет
};

/**
 * Сущность характеристики, отправляемая на бэк
 */
export type ProductOptionEditingFieldsServer =
  | Omit<ProductOptionServer, 'id' | 'option_id'>
  | {
      id: number | null;
      option_id: number | null;
      name?: string;
    };

/**
 * Сущность характеристики модификации, отправляемая на бэк
 */
export type ModificationOptionEditingFieldsServer = {
  id: number | null;
  option_id: number;
};

export type ProductOptionBaseServer = {
  id: number;
  type: ProductOptionType;
  name: string;
};

export type ShopbackProductOptionsServer = {
  options: ProductOptionBaseServer[];
};

export type ProductOptionModifiersServer = {
  modificators: ProductOptionModifierServer[];
};

export type ProductOptionModifierServer = {
  id: number;
  option_id: number;
  value: string; // Значение характеристики, в случае цвета - название цвета
  extra_value?: HexString; // В случае цвета - HEX-цвет
};

export enum ProductOptionModifierDeleteError {
  MODIFICATOR_IS_USED = 'modificator_is_used'
}

export const mapModifierDeleteErrorCodeToMessage =
  (code: ProductOptionModifierDeleteError): TranslationString =>
  (t) =>
    t(`shopback.options.errors.productOptionModifierDelete.${code}`, {
      ns: 'entities'
    });

export enum ProductOptionModifierCreateError {
  MODIFICATOR_ALREADY_EXISTS = 'modificator_already_exists'
}

export const mapModifierCreateErrorCodeToMessage =
  (code: ProductOptionModifierCreateError): TranslationString =>
  (t) =>
    t(`shopback.options.errors.productOptionModifierCreate.${code}`, {
      ns: 'entities'
    });
