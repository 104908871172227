export enum CommonDirection {
  left = 'left',
  right = 'right',
  top = 'top',
  bottom = 'bottom'
}

export enum CSSWidthKind {
  inPercent = 'in_percent',
  inPx = 'in_px'
}
