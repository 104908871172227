import * as React from 'react';

import { BaseModalProps } from 'shared/newEntities/components/BaseModal';
import {
  TranslationNode,
  TranslationString
} from 'shared/entities/localization';
import { ButtonProps } from 'shared/newEntities/components/Button';
import { ComponentType } from 'shared/entities/components/Component';

import Modal from '../Modal';

import Content from './Content';

import './FormModal.modules.scss';

type Props = React.PropsWithChildren<
  BaseModalProps & {
    title: TranslationString;
    ok: ButtonProps;
    withoutCancelButton?: boolean;
    onEnter?: () => void;
    className?: string;
    rightFooter?: boolean;
    footnotes?: TranslationNode;
    cancelButtonTitle?: TranslationString;
  }
>;

const FormModal: React.FC<Props> = ({
  children,
  ok,
  onEnter,
  withoutCancelButton = false,
  className,
  rightFooter,
  footnotes,
  cancelButtonTitle,
  ...rest
}: Props) => {
  return (
    <Modal {...rest} styleName="form-modal" className={className}>
      <Content
        opened={rest.opened}
        ok={ok}
        cancel={
          withoutCancelButton
            ? undefined
            : {
                type: ComponentType.button,
                onClick: rest.onClose,
                children: cancelButtonTitle
              }
        }
        onEnter={onEnter}
        rightFooter={rightFooter}
        footnotes={footnotes}
      >
        {children}
      </Content>
    </Modal>
  );
};

export default FormModal;
