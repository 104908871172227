import * as React from 'react';

import WithBorder from 'shared/newComponents/WithBorder';
import { PopupTrigger } from 'shared/entities/components/Popup';
import { SelectorTriggerSize } from 'shared/newEntities/components/Selector';

import styles from './Trigger.modules.scss';

type Props = {
  isSelectorOpened: boolean;
  isError: boolean;
  disabled?: boolean;
  withoutBorder?: boolean;
  children: PopupTrigger;
  size?: SelectorTriggerSize;
};

const Trigger: React.FC<Props> = ({
  isSelectorOpened,
  isError,
  disabled,
  withoutBorder,
  children,
  size = SelectorTriggerSize.l
}) => {
  const content = (
    <div styleName="trigger" className={styles[`trigger_${size}`]}>
      {React.isValidElement(children) ? (
        <>{children}</>
      ) : (
        children(isSelectorOpened)
      )}
    </div>
  );

  if (withoutBorder) {
    return content;
  }

  return (
    <WithBorder focus={isSelectorOpened} error={isError} disabled={disabled}>
      {content}
    </WithBorder>
  );
};

export default Trigger;
