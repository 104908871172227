import * as React from 'react';
import { observer } from 'mobx-react';

import { MAIN_ELEMENTS_ID } from 'shared/entities/app';
import { useUiStore } from 'stores/index';

import ContentWindow, { ContentWindowProps } from '../ContentWindow';
import SideWindowContainer from '../SideWindowContainer';
import ResizeableWindow from '../ResizeableWindow';

import './SideWindow.modules.scss';

type Props = ContentWindowProps & {
  opened: boolean;
  onClick?: () => void;
  initialWidth?: number;
  onChangeWidth?: (value: number) => void;
  className?: string;
};

const SideWindow = React.forwardRef(
  (
    {
      opened,
      onClick,
      initialWidth,
      onChangeWidth,
      className,
      ...props
    }: Props,
    ref: React.MutableRefObject<HTMLDivElement>
  ) => {
    const uiStore = useUiStore();
    return (
      <SideWindowContainer opened={opened}>
        <div
          ref={ref}
          id={MAIN_ELEMENTS_ID.SIDE_WINDOW_CONTAINER}
          styleName="side-window-container"
          onClick={onClick}
          className={className}
        >
          {uiStore.isMobileDevice ? (
            <ContentWindow {...props} scroll styleName="side-window" />
          ) : (
            <ResizeableWindow
              initialWidth={initialWidth}
              onChangeWidth={onChangeWidth}
            >
              <ContentWindow {...props} scroll styleName="side-window" />
            </ResizeableWindow>
          )}
        </div>
      </SideWindowContainer>
    );
  }
);

export default observer(SideWindow);
