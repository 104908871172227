import { action, makeObservable, observable } from 'mobx';

import { EditProjectCommonVarModel } from 'shared/models/vars';
import {
  CommonVarErrorCode,
  mapVarUpdateErrorToMessage,
  mapVarCreateErrorToMessage,
  mapVarCommonErrorToMessage,
  UpdateVarErrorCode,
  VarLevel,
  VarType,
  IProjectVar
} from 'shared/entities/vars';
import { IProjectVariablesStore } from 'shared/entities/store/variablesStore';
import { IRootStore } from 'shared/entities/store/rootStore';
import { BaseResponseCode } from 'shared/entities/network';

import { mapVarTypeToValidator, VarUpdateStage } from './config';

export default class ProjectCommonVarEditStore {
  entity: EditProjectCommonVarModel;
  readonly projectVariablesStore: IProjectVariablesStore;
  readonly rootStore: IRootStore;

  varUpdateStage: VarUpdateStage = VarUpdateStage.form;

  constructor({
    entity,
    projectVariablesStore,
    projectId,
    defaultVarLevel,
    rootStore
  }: {
    entity?: EditProjectCommonVarModel;
    projectId: string;
    projectVariablesStore: IProjectVariablesStore;
    rootStore: IRootStore;
    defaultVarLevel?: VarLevel;
  }) {
    this.entity =
      entity ||
      EditProjectCommonVarModel.fromDefaultParams({
        projectId,
        rootStore,
        varLevel: defaultVarLevel
      });

    this.projectVariablesStore = projectVariablesStore;
    this.rootStore = rootStore;

    makeObservable(this, {
      update: action,
      add: action,
      validateValue: action,
      varUpdateStage: observable,
      changeVarUpdateStage: action,
      reset: action
    });
  }

  changeVarUpdateStage = (value: VarUpdateStage): void => {
    this.varUpdateStage = value;
  };

  validateValue = (): void => {
    if (this.entity.data.type === VarType.expr) {
      this.entity.data.value.validate();
    }

    const validator = mapVarTypeToValidator[this.entity.data.type];

    if (!validator) {
      return;
    }

    validator(this.entity.data.value);
  };

  async update(): Promise<BaseResponse> {
    this.entity.validate();

    if (this.entity.error) {
      return {
        isError: true
      };
    }
    // Отправляет на сервер обновленную переменную
    const response = await this.entity.update();

    if (!response.isError) {
      this.projectVariablesStore.updateVariable(response.data.updatedVariable);
    }

    if (response.isError && response.data.errorCode) {
      if (response.data.errorCode === CommonVarErrorCode.invalidVarFunction) {
        this.entity.valueError.changeValue(
          mapVarCommonErrorToMessage(CommonVarErrorCode.invalidVarFunction)
        );

        return {
          isError: true
        };
      }

      if (response.data.errorCode === CommonVarErrorCode.invalidValueForType) {
        this.entity.typeError.changeValue(
          mapVarCommonErrorToMessage(CommonVarErrorCode.invalidValueForType)
        );

        return {
          isError: true
        };
      }

      if (Object.values(UpdateVarErrorCode).includes(response.data.errorCode)) {
        this.entity.key.setError(
          mapVarUpdateErrorToMessage(response.data.errorCode)
        );

        return {
          isError: true
        };
      }
    }
    return {
      isError: response.isError
    };
  }

  async add(): Promise<
    { isError: true } | { isError: false; data: IProjectVar }
  > {
    this.entity.validate();

    if (this.entity.error) {
      return {
        isError: true
      };
    }

    const response = await this.entity.add();

    if (response.isError && response.data.errorCode) {
      if (response.data.errorCode === CommonVarErrorCode.invalidVarFunction) {
        this.entity.valueError.changeValue(
          mapVarCommonErrorToMessage(CommonVarErrorCode.invalidVarFunction)
        );
      }

      if (response.data.errorCode === CommonVarErrorCode.invalidValueForType) {
        this.entity.typeError.changeValue(
          mapVarCommonErrorToMessage(CommonVarErrorCode.invalidValueForType)
        );
      }

      if (response.data.errorCode === BaseResponseCode.conflict) {
        this.entity.key.setError(
          mapVarCreateErrorToMessage(BaseResponseCode.conflict)
        );
      }

      return {
        isError: true
      };
    }

    if (!response.isError) {
      const model = this.projectVariablesStore.addVariable(
        response.data.createdVariable
      );
      this.reset();
      return {
        isError: false,
        data: model
      };
    }

    return {
      isError: true
    };
  }

  reset(): void {
    this.varUpdateStage = VarUpdateStage.form;

    if (this.rootStore.projectId) {
      this.entity = EditProjectCommonVarModel.fromDefaultParams({
        rootStore: this.rootStore,
        projectId: this.rootStore.projectId,
        varLevel: this.entity.level.value
      });
    }
  }

  updateEntity(entity: EditProjectCommonVarModel): void {
    this.entity = entity.getCopy();
  }
}
