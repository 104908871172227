import * as React from 'react';
import cn from 'classnames';

import { Button } from 'shared/newComponents/buttons';
import { TranslationNode } from 'shared/entities/localization';
import {
  ButtonColor,
  ButtonProps,
  ButtonSize
} from 'shared/newEntities/components/Button';
import { useKeyboardEventsStore } from 'stores/index';
import {
  KeyboardEventListener,
  KeyboardEventType
} from 'shared/entities/keyboardEvents';
import TransComp from 'shared/components/TransComp';

import './Content.modules.scss';

type Props = React.PropsWithChildren<{
  ok: ButtonProps;
  className?: string;
  rightFooter?: boolean;
  footnotes?: TranslationNode;
  cancel?: ButtonProps;
  opened: boolean;
  onEnter?: () => void;
}>;

const Content: React.FC<Props> = ({
  children,
  ok,
  onEnter,
  rightFooter,
  footnotes,
  cancel,
  opened
}: Props) => {
  const keyboardEventsStore = useKeyboardEventsStore();

  const handleEnter = React.useCallback<KeyboardEventListener>(() => {
    onEnter?.();

    return true;
  }, [onEnter]);

  React.useEffect(() => {
    if (!onEnter) {
      return;
    }

    if (opened) {
      keyboardEventsStore.addListener({
        type: KeyboardEventType.enter,
        listener: handleEnter
      });
    }

    return () => {
      if (opened) {
        keyboardEventsStore.removeListener({
          type: KeyboardEventType.enter,
          listener: handleEnter
        });
      }
    };
  }, [handleEnter, opened]);

  const buttonsOnTheRight = !!cancel && rightFooter;

  return (
    <>
      {' '}
      <div styleName="content">
        <div styleName="content-container">{children}</div>
      </div>
      <div
        styleName={cn(
          'footer',
          cancel && 'footer_two-button',
          buttonsOnTheRight && 'footer_two-button_right'
        )}
      >
        {footnotes && (
          <div styleName="footer-footnotes">
            <TransComp>{footnotes}</TransComp>
          </div>
        )}
        {cancel && (
          <Button
            {...{
              ...cancel,
              color: cancel.color || ButtonColor.gray,
              size: buttonsOnTheRight ? ButtonSize.sm : ButtonSize.md,
              children:
                cancel.children ||
                ((t) =>
                  t('FormModal.cancel', {
                    ns: 'newComponents'
                  }))
            }}
          />
        )}
        <Button
          {...ok}
          color={ButtonColor.blue}
          fullWidth={!cancel}
          size={buttonsOnTheRight ? ButtonSize.sm : ButtonSize.md}
        >
          {ok.children ||
            ((t) =>
              t('FormModal.ok', {
                ns: 'newComponents'
              }))}
        </Button>
      </div>
    </>
  );
};

export default Content;
