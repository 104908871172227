import { CSSWidthKind } from '../client';

import convertStringToNumber from './convertStringToNumber';

const getCssWidthKind = (value: string | number): CSSWidthKind | null => {
  if (typeof value === 'number') {
    return CSSWidthKind.inPx;
  }

  if (
    typeof value === 'string' &&
    value.length > 1 &&
    value[value.length - 1] === '%' &&
    typeof convertStringToNumber(value.substring(0, value.length - 1)) ===
      'number'
  ) {
    return CSSWidthKind.inPercent;
  }

  return null;
};

export default getCssWidthKind;
