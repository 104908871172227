import * as React from 'react';
import { observer } from 'mobx-react';

import {
  SelectorItem,
  SelectorKey,
  SelectorOptions,
  SelectorType
} from 'shared/newEntities/components/Selector';
import { IconComponent, IconSize } from 'shared/entities/components/Icon';
import { SearchIcon, ArrowIcon } from 'shared/newComponents/icons';
import {
  renderTranslationString,
  TFunctionType,
  TranslationString,
  useTypedTranslation
} from 'shared/entities/localization';
import Typography from 'shared/newComponents/Typography';
import {
  TypographyColor,
  TypographySize,
  TypographyType,
  TypographyWeight
} from 'shared/newEntities/components/Typography';
import CroppedContent from 'shared/newComponents/CroppedContent';
import { useUiStore } from 'stores/index';
import { ComponentType } from 'shared/entities/components/Component';
import Reference from 'shared/newComponents/Reference';
import { TagProps, TagSize } from 'shared/newEntities/components/Tag';
import Tag from 'shared/newComponents/Tag';

import './Trigger.modules.scss';

type Props = {
  options: SelectorOptions;
  isSelectorOpened: boolean;
  searchText: string;
  onChangeSearchText: (value: string) => void;
  pluralize?: (count: number) => TranslationString;
  placeholder?: TranslationString | string;
  title?: TranslationString | string;
  Icon?: IconComponent;
  withoutSearch?: boolean;
  withCroppedTitle?: boolean;
} & (
  | {
      type: SelectorType.single;
      value: SelectorKey | null;
      onChange: (value: SelectorKey | null) => void;
    }
  | {
      type: SelectorType.multi;
      value: SelectorKey[];
      onChange: (value: SelectorKey[]) => void;
    }
);

const Trigger: React.FC<Props> = ({
  isSelectorOpened,
  searchText,
  onChangeSearchText,
  options,
  pluralize,
  placeholder,
  title,
  Icon,
  withoutSearch = false,
  withCroppedTitle = true,
  ...rest
}: Props) => {
  const { t } = useTypedTranslation();
  const uiStore = useUiStore();

  const IconComp = React.useMemo((): IconComponent | null => {
    const DefaultIcon = withoutSearch ? null : SearchIcon;
    if (title) {
      return null;
    }

    if (rest.type === SelectorType.single) {
      const entity = rest.value !== null ? options.entities[rest.value] : null;

      if (!isSelectorOpened) {
        if (entity) {
          return entity.Icon || Icon || null;
        }

        return Icon || DefaultIcon;
      }

      return DefaultIcon;
    }

    const value = rest.value.reduce<SelectorItem[]>((acc, itemKey) => {
      const entity = options.entities[itemKey];

      if (entity) {
        acc.push(entity);
      }

      return acc;
    }, []);

    if (!isSelectorOpened) {
      if (!value.length) {
        return Icon || DefaultIcon;
      }

      return null;
    }

    return DefaultIcon;
  }, [
    rest.type,
    rest.value,
    isSelectorOpened,
    options.entities,
    title,
    withoutSearch
  ]);

  const titleObj = React.useMemo(():
    | {
        value: TranslationString | string;
        type: 'value';
        isDefault?: boolean;
        rightComponent?: React.ReactNode;
        tag?: TagProps;
      }
    | { type: 'search' } => {
    if (title) {
      return {
        value: title,
        type: 'value'
      };
    }

    const defaultTitleObj:
      | {
          value: TranslationString | string;
          type: 'value';
          isDefault?: boolean;
        }
      | { type: 'search' } = withoutSearch
      ? {
          type: 'value',
          value: placeholder || '',
          isDefault: true
        }
      : { type: 'search' };

    if (rest.type === SelectorType.single) {
      const entity = rest.value !== null ? options.entities[rest.value] : null;

      if (!entity) {
        return defaultTitleObj;
      }

      if (withoutSearch || !isSelectorOpened) {
        const { title, rightComponent, tagProps } = entity;

        let extraComponent: React.ReactNode = null;

        if (rightComponent) {
          const { Icon, reference, onIconClick } = rightComponent;

          const rightIcon: React.ReactNode = (
            <Icon
              styleName="trigger__right-icon"
              action={
                onIconClick
                  ? {
                      type: ComponentType.svg,
                      onClick: (e) => {
                        e.stopPropagation();
                        onIconClick?.(entity.id);
                      }
                    }
                  : undefined
              }
              iconSize={IconSize.XM}
            />
          );

          extraComponent = reference ? (
            <Reference
              disabledOnMobile
              title={reference}
              trigger={
                <div styleName="trigger__right-icon-container">{rightIcon}</div>
              }
            />
          ) : (
            rightIcon
          );
        }

        return {
          value: title,
          type: 'value',
          rightComponent: extraComponent,
          tag: tagProps
        };
      }

      return { type: 'search' };
    }

    const value = rest.value.reduce<SelectorItem[]>((acc, itemKey) => {
      const entity = options.entities[itemKey];

      if (entity) {
        acc.push(entity);
      }

      return acc;
    }, []);
    if (!value.length) {
      return defaultTitleObj;
    }

    if (withoutSearch || !isSelectorOpened) {
      return pluralize
        ? { value: pluralize(value.length), type: 'value' }
        : {
            value: (t: TFunctionType) =>
              t('Selector.pluralize', {
                ns: 'newComponents',
                count: value.length
              }),
            type: 'value'
          };
    }

    return { type: 'search' };
  }, [
    rest.type,
    options.entities,
    isSelectorOpened,
    rest.value,
    pluralize,
    withoutSearch
  ]);

  const handleChange = React.useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >(
    (e) => {
      onChangeSearchText(e.target.value);
    },
    [onChangeSearchText]
  );

  return (
    <div styleName="trigger">
      <div styleName="trigger__left">
        {IconComp && (
          <IconComp styleName="trigger__icon" iconSize={IconSize.XM} />
        )}
        {titleObj.type === 'value' && (
          <div styleName="trigger__left">
            <div styleName="trigger__left-value">
              <div styleName="trigger__left-container">
                {withCroppedTitle ? (
                  <CroppedContent popupContent={titleObj.value}>
                    <Typography
                      fontType={TypographyType.text}
                      fontSize={TypographySize.m}
                      fontWeight={
                        titleObj.isDefault
                          ? TypographyWeight.normal
                          : TypographyWeight.heavy
                      }
                      fontColor={
                        titleObj.isDefault
                          ? TypographyColor.tertiary
                          : TypographyColor.main
                      }
                      styleName="trigger__value"
                    >
                      {titleObj.value}
                    </Typography>
                  </CroppedContent>
                ) : (
                  <Typography
                    fontType={TypographyType.text}
                    fontSize={TypographySize.m}
                    fontWeight={
                      titleObj.isDefault
                        ? TypographyWeight.normal
                        : TypographyWeight.heavy
                    }
                    fontColor={
                      titleObj.isDefault
                        ? TypographyColor.tertiary
                        : TypographyColor.main
                    }
                    styleName="trigger__value"
                  >
                    {titleObj.value}
                  </Typography>
                )}
                {titleObj.tag && (
                  <Tag
                    styleName="trigger__left-tag"
                    size={TagSize.xs}
                    {...titleObj.tag}
                  />
                )}
              </div>
            </div>
            {titleObj.rightComponent}
          </div>
        )}
        {titleObj.type === 'search' && (
          <input
            onClick={(e) => {
              if (isSelectorOpened) {
                e.stopPropagation();
              }
            }}
            autoFocus={uiStore.isMobile ? undefined : isSelectorOpened}
            tabIndex={uiStore.isMobile ? -1 : undefined}
            styleName="trigger__search"
            value={searchText}
            onChange={handleChange}
            placeholder={renderTranslationString(
              placeholder ||
                ((t) =>
                  t('Selector.search', {
                    ns: 'newComponents'
                  })),
              t
            )}
          />
        )}
      </div>
      <ArrowIcon
        styleName="trigger__right-icon"
        direction={isSelectorOpened ? 'top' : 'bottom'}
      />
    </div>
  );
};

export default observer(Trigger);
