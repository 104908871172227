import * as React from 'react';

import { CommonDirection, CSSWidthKind } from '../client';
import { getCssWidthKind } from '../utils';

import useEventListener from './useEventListenerHook';

const useResizableWindow = ({
  initialWidth,
  directionOfExpansion,
  minWidth = 0,
  maxWidth
}: {
  initialWidth: number | string;
  directionOfExpansion: CommonDirection.right | CommonDirection.left;
  minWidth?: number;
  maxWidth?: number;
}): {
  onMouseDown: () => void;
  isDragging: boolean;
  width: number | string;
  setWidth: (value: number | string) => void;
  ref: React.MutableRefObject<HTMLDivElement | null>;
} => {
  const [width, setWidth] = React.useState<number | string>(initialWidth);
  const ref = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    const widthKind = getCssWidthKind(initialWidth);

    if (widthKind === CSSWidthKind.inPx) {
      setWidth(initialWidth);
    }
    if (!ref.current) {
      return;
    }
    setWidth(ref.current.clientWidth);
  }, [initialWidth]);

  const [isDragging, setIsDragging] = React.useState(false);

  React.useEffect(() => {
    if (
      typeof initialWidth === 'string' &&
      initialWidth.endsWith('%') &&
      ref.current
    ) {
      setWidth(ref.current.clientWidth);
    }
  }, [initialWidth]);

  const onMouseDown = React.useCallback(() => {
    setIsDragging(true);
  }, []);

  const onMouseUp = React.useCallback(() => {
    setIsDragging(false);
  }, []);

  const onMouseMove = React.useCallback(
    (e: MouseEvent) => {
      if (!isDragging) {
        return;
      }
      setWidth((currentSize) => {
        if (!ref.current) {
          return initialWidth;
        }

        if (!currentSize || typeof currentSize !== 'number') {
          return ref.current.clientWidth;
        }

        const nextSize =
          directionOfExpansion === CommonDirection.right
            ? currentSize + e.movementX / 2
            : currentSize - e.movementX / 2;

        if (nextSize < minWidth) {
          return minWidth;
        }

        if (maxWidth && nextSize > maxWidth) {
          return maxWidth;
        }

        return nextSize;
      });
    },
    [isDragging, directionOfExpansion, minWidth, maxWidth]
  );

  useEventListener({
    eventName: 'mousemove',
    handler: onMouseMove,
    getElement: () => document
  });

  useEventListener({
    eventName: 'mouseup',
    handler: onMouseUp,
    getElement: () => document
  });

  return {
    onMouseDown,
    isDragging,
    width,
    setWidth,
    ref
  };
};

export default useResizableWindow;
