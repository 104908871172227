import * as React from 'react';
import cn from 'classnames';

import { noop } from 'shared/entities/common/utils';
import {
  ButtonColor,
  ButtonProps,
  ButtonStyle
} from 'shared/entities/components/Button';
import { LoadingStage } from 'shared/entities/meta';
import { ComponentType } from 'shared/entities/components/Component';
import Button from 'shared/components/buttons/Button';
import { useTypedTranslation } from 'shared/entities/localization';
import {
  KeyboardEventListener,
  KeyboardEventType
} from 'shared/entities/keyboardEvents';
import { useKeyboardEventsStore } from 'stores';

import './Content.scss';

type Props = {
  children?: React.ReactNode;
  ok?: (ButtonProps & { ref?: React.MutableRefObject<any> }) | null;
  cancel?: (ButtonProps & { ref?: React.MutableRefObject<any> }) | null;
  onClose?: () => void;
  withContentError?: boolean;
};

const Content: React.FC<Props> = ({
  children,
  ok: okProp,
  cancel: cancelProp,
  onClose,
  withContentError
}: Props) => {
  const keyboardEventsStore = useKeyboardEventsStore();

  const { t } = useTypedTranslation('components');
  const cancelTitle = t('ModalWindow.Content.cancelButtonTitle');
  const submitTitle = t('ModalWindow.Content.submitButtonTitle');

  const cancel =
    cancelProp === null
      ? null
      : cancelProp || {
          children: cancelTitle,
          type: ComponentType.button,
          onClick: onClose || noop,
          loadingStage: LoadingStage.NOT_STARTED,
          disabled: false
        };

  const ok =
    okProp === null
      ? null
      : okProp || {
          children: submitTitle,
          type: ComponentType.button,
          onClick: noop,
          loadingStage: LoadingStage.NOT_STARTED,
          disabled: false
        };

  const handleEnterEvent =
    React.useCallback<KeyboardEventListener>(async () => {
      if (ok?.type === ComponentType.button) {
        ok.onClick();
      }

      return false;
    }, [ok]);

  React.useEffect(() => {
    keyboardEventsStore.addListener({
      type: KeyboardEventType.enter,
      listener: handleEnterEvent
    });

    return () => {
      keyboardEventsStore.removeListener({
        type: KeyboardEventType.enter,
        listener: handleEnterEvent
      });
    };
  }, [handleEnterEvent]);

  return (
    <div className="modal-content">
      <div
        className={cn(
          'modal-content__content',
          withContentError && 'modal-content__content_with-error'
        )}
      >
        {withContentError ? (
          <div className="modal-content__content-with-error">{children}</div>
        ) : (
          children
        )}
      </div>
      {(!!cancel || !!ok) && (
        <div className="modal-content__footer">
          {cancel && (
            <Button
              className="modal-content__button"
              {...{
                ...cancel,
                color: cancel.color || ButtonColor.gray,
                style: cancel.style || ButtonStyle.common,
                children: cancel.children || cancelTitle
              }}
              fullWidth
              ref={cancel.ref}
            />
          )}
          {ok && (
            <Button
              className="modal-content__button"
              {...{
                ...ok,
                children: ok.children || submitTitle
              }}
              fullWidth
              ref={ok.ref}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Content;
